import MainLayout from "../layouts/MainLayout";
import ImageGallery from 'react-image-gallery';
import {Helmet} from "react-helmet";

function GalleryPage() {

  const images = [
    {original: require("../assets/images/gallery/DSC_0921.jpg"), thumbnail: require("../assets/images/gallery/DSC_0921.jpg"), originalAlt: "фото галереи"},
    {original: require("../assets/images/gallery/DSC_1002.jpg"), thumbnail: require("../assets/images/gallery/DSC_1002.jpg"), originalAlt: "фото галереи"},
    {original: require("../assets/images/gallery/DSC_1139.jpg"), thumbnail: require("../assets/images/gallery/DSC_1139.jpg"), originalAlt: "фото галереи"},
    {original: require("../assets/images/gallery/DSC_1192.jpg"), thumbnail: require("../assets/images/gallery/DSC_1192.jpg"), originalAlt: "фото галереи"},
    {original: require("../assets/images/gallery/DSC_1222.jpg"), thumbnail: require("../assets/images/gallery/DSC_1222.jpg"), originalAlt: "фото галереи"},
    {original: require("../assets/images/gallery/DSC_2523.jpg"), thumbnail: require("../assets/images/gallery/DSC_2523.jpg"), originalAlt: "фото галереи"},
    {original: require("../assets/images/gallery/DSC_2532.jpg"), thumbnail: require("../assets/images/gallery/DSC_2532.jpg"), originalAlt: "фото галереи"},
    {original: require("../assets/images/gallery/DSC_2569.jpg"), thumbnail: require("../assets/images/gallery/DSC_2569.jpg"), originalAlt: "фото галереи"},
    {original: require("../assets/images/gallery/DSC_2613.jpg"), thumbnail: require("../assets/images/gallery/DSC_2613.jpg"), originalAlt: "фото галереи"},
    {original: require("../assets/images/gallery/DSC_4145.jpg"), thumbnail: require("../assets/images/gallery/DSC_4145.jpg"), originalAlt: "фото галереи"},
    {original: require("../assets/images/gallery/DSC_4292.jpg"), thumbnail: require("../assets/images/gallery/DSC_4292.jpg"), originalAlt: "фото галереи"},
    {original: require("../assets/images/gallery/DSC_4281.jpg"), thumbnail: require("../assets/images/gallery/DSC_4281.jpg"), originalAlt: "фото галереи"},
    {original: require("../assets/images/gallery/DSC_4271.jpg"), thumbnail: require("../assets/images/gallery/DSC_4271.jpg"), originalAlt: "фото галереи"},
    {original: require("../assets/images/gallery/DSC_4269.jpg"), thumbnail: require("../assets/images/gallery/DSC_4269.jpg"), originalAlt: "фото галереи"},
    {original: require("../assets/images/gallery/DSC_4242.jpg"), thumbnail: require("../assets/images/gallery/DSC_4242.jpg"), originalAlt: "фото галереи"},
    {original: require("../assets/images/gallery/DSC_0942.jpg"), thumbnail: require("../assets/images/gallery/DSC_0942.jpg"), originalAlt: "фото галереи"},
    {original: require("../assets/images/gallery/DSC_4159.jpg"), thumbnail: require("../assets/images/gallery/DSC_4159.jpg"), originalAlt: "фото галереи"},
  ];

  return (
    <MainLayout>

      <Helmet>
        <title>Школа борьбы Superstar | Галерея</title>
        <meta name="description" content="Галерея" />
        <meta name="keywords" content="галерея" />
      </Helmet>

      <section className="section-padding">
        <div className="container">
          <div className="flex-lg-row">
            <div className="col-lg-12">
              <div className="section-top text-center">
                <h1>Галерея</h1>
              </div>
            </div>
          </div>
        </div>

        <ImageGallery items={images} />
      </section>
    </MainLayout>
  );
}

export default GalleryPage;