import {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {CONTACT_PAGE_ROUTE, GALLERY_PAGE_ROUTE, MAIN_PAGE_ROUTE, SCHEDULE_PAGE_ROUTE} from "../utils/consts";

function Header() {

  const isMobile = window.innerWidth <= 1199;
  const [isOpenMenu, setIsOpenMenu] = useState(!isMobile)

  const toggleMenu = () => {
    setIsOpenMenu(prev => !prev)
  }

  const closeMenu = () => {
    const isMobile = window.innerWidth <= 1199;
    if(isMobile) {
      setIsOpenMenu(false)
    }
  }
  function handleWindowSizeChange() {
    const isMobile = window.innerWidth <= 1199;
    if(isMobile) {
      setIsOpenMenu(false)
    }else{
      setIsOpenMenu(true)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  return (
    <header className="header-area main-header">
      <div className="container">
        <div className="row">
          <div className="col-lg-2">
            <div className="logo-area animate__animated animate__pulse">
              <NavLink to={MAIN_PAGE_ROUTE} onClick={() => closeMenu()}><img src={require("../assets/images/logo.png")} alt="logo"/></NavLink>
            </div>
          </div>
          <div className="col-lg-10">
            <div className="custom-navbar" onClick={() => toggleMenu()}>
              <span/>
              <span/>
              <span/>
            </div>

            <div className="main-menu">
              {isOpenMenu &&
                <ul>
                  <li className="active">
                    <NavLink to={MAIN_PAGE_ROUTE} onClick={() => closeMenu()}>Главная</NavLink>
                  </li>
                  <li>
                    <NavLink to={{pathname: MAIN_PAGE_ROUTE, hash: "#about"}} onClick={() => closeMenu()}>О нас</NavLink>
                  </li>
                  <li>
                    <NavLink to={{pathname: MAIN_PAGE_ROUTE, hash: "#trainers"}} onClick={() => closeMenu()}>Тренеры</NavLink>
                  </li>
                  <li>
                    <NavLink to={{pathname: MAIN_PAGE_ROUTE, hash: "#reviews"}} onClick={() => closeMenu()}>Отзывы</NavLink>
                  </li>
                  <li>
                    <NavLink to={SCHEDULE_PAGE_ROUTE} onClick={() => closeMenu()}>Расписание</NavLink>
                  </li>
                  <li>
                    <NavLink to={CONTACT_PAGE_ROUTE} onClick={() => closeMenu()}>Контакты</NavLink>
                  </li>
                  <li>
                    <NavLink to={GALLERY_PAGE_ROUTE} onClick={() => closeMenu()}>Галерея</NavLink>
                  </li>
                </ul>
              }

            </div>

          </div>
        </div>
      </div>
    </header>
  );
}

export default Header
