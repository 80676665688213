import MainLayout from "../layouts/MainLayout"
import {Helmet} from "react-helmet";

function SchedulePage() {
  return (
    <MainLayout>

      <Helmet>
        <title>Школа борьбы Superstar | Расписание</title>
        <meta name="description" content="Расписание тренировок" />
        <meta name="keywords" content="расписание" />
      </Helmet>

      <section className="schedule-area section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-top text-center">
                <h1>Расписание тренировок</h1>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">


            <div className="table-wrap p-0 col-lg-10 border rounded my-2 animate__animated animate__backInUp">
              <h4 className="text-dark text-center my-3">Расписание для филиала по адресу ул. Поляны, 5(этаж 3)</h4>
              <table className="schdule-table table mt-3" style={{fontSize: "14px"}}>
                <thead className="thead-light">
                <tr>
                  <th className="head pl-2" scope="col">Группа</th>
                  <th className="head" scope="col">ПН</th>
                  <th className="head" scope="col">ВТ</th>
                  <th className="head" scope="col">СР</th>
                  <th className="head" scope="col">ЧТ</th>
                  <th className="head" scope="col">ПТ</th>
                  <th className="head" scope="col">СБ</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th className="name" scope="row">4-6 лет</th>
                  <td>x</td><td>17:00-18:00</td><td>x</td><td>17:00-18:00</td><td>x</td><td>x</td>
                </tr>
                <tr>
                  <th className="name" scope="row">7-9 лет</th>
                  <td>x</td><td>18:05-19:15</td><td>x</td><td>18:05-19:15</td><td>x</td><td>11:00-12:15</td>
                </tr>
                <tr>
                  <th className="name" scope="row">10+ лет</th>
                  <td>x</td><td>19:20-20:40</td><td>x</td><td>19:20-20:40</td><td>x</td><td>11:00-12:15</td>
                </tr>
                </tbody>
              </table>
            </div>

            <div className="table-wrap p-0 col-lg-10 border rounded my-2 animate__animated animate__backInUp">
              <h4 className="text-dark text-center my-3">Расписание для филиала по адресу ул. Старобитцевская, 15, корп. 3</h4>
              <table className="schdule-table table mt-3" style={{fontSize: "14px"}}>
                <thead className="thead-light">
                <tr>
                  <th className="head pl-2" scope="col">Группа</th>
                  <th className="head" scope="col">ПН</th>
                  <th className="head" scope="col">ВТ</th>
                  <th className="head" scope="col">СР</th>
                  <th className="head" scope="col">ЧТ</th>
                  <th className="head" scope="col">ПТ</th>
                  <th className="head" scope="col">СБ</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th className="name" scope="row">4-6 лет</th>
                  <td>16:50-17:50</td><td>x</td><td>16:50-17:50</td><td>x</td><td>16:50-17:50</td><td>x</td>
                </tr>
                <tr>
                  <th className="name" scope="row">5+ лет</th>
                  <td>x</td><td>17:20-18:30</td><td>x</td><td>17:20-18:30</td><td>x</td><td>x</td>
                </tr>
                <tr>
                  <th className="name" scope="row">7-11 лет</th>
                  <td>17:55-19:05</td><td>x</td><td>17:55-19:05</td><td>x</td><td>17:55-19:05</td><td>x</td>
                </tr>
                <tr>
                  <th className="name" scope="row">12+ лет</th>
                  <td>19:15-20:35</td><td>x</td><td>19:15-20:35</td><td>x</td><td>19:15-20:35</td><td>x</td>
                </tr>
                </tbody>
              </table>
            </div>

            <div className="table-wrap p-0 col-lg-10 border rounded my-2 animate__animated animate__backInUp">
              <h4 className="text-dark text-center my-3">Расписание для филиала по адресу ул. Бартеневская, 19</h4>
              <table className="schdule-table table mt-3" style={{fontSize: "14px"}}>
                <thead className="thead-light">
                <tr>
                  <th className="head pl-2" scope="col">Группа</th>
                  <th className="head" scope="col">ПН</th>
                  <th className="head" scope="col">ВТ</th>
                  <th className="head" scope="col">СР</th>
                  <th className="head" scope="col">ЧТ</th>
                  <th className="head" scope="col">ПТ</th>
                  <th className="head" scope="col">СБ</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th className="name" scope="row">4-6 лет</th>
                  <td>16:50-17:50</td><td>x</td><td>16:50-17:50</td><td>x</td><td>16:50-17:50</td><td>x</td>
                </tr>
                <tr>
                  <th className="name" scope="row">7+ лет</th>
                  <td>18:00-19:10</td><td>x</td><td>18:00-19:10</td><td>x</td><td>18:00-19:10</td><td>x</td>
                </tr>


                </tbody>
              </table>
            </div>



          </div>
        </div>
      </section>

    </MainLayout>
  );
}

export default SchedulePage;