import {Routes, Route, useLocation} from 'react-router-dom'
import {routes} from "../routes"
import {useEffect} from "react";


const AppRouter = () => {

  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    if (hash === '') {
      setTimeout(() => {
        window.scrollTo({top:0,left:0, behavior: "smooth"});
      }, 0)
    }
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({behavior: "smooth"});
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  return (
    <Routes>
      {routes.map(({path, element}) =>
        <Route key={path} path={path} element={element}/>
      )}
    </Routes>
  );
}

export default AppRouter;
