import * as React from 'react';
import {Navigate} from 'react-router-dom';

import {SCHEDULE_PAGE_ROUTE, MAIN_PAGE_ROUTE, CONTACT_PAGE_ROUTE, GALLERY_PAGE_ROUTE} from "./utils/consts";
import MainPage from "./pages/MainPage";
import NoMatchPage from "./pages/NoMatchPage";
import SchedulePage from "./pages/SchedulePage";
import ContactPage from "./pages/ContactPage";
import GalleryPage from "./pages/GalleryPage";


export const routes = [
  {
    path: "/",
    element: <Navigate to={MAIN_PAGE_ROUTE}/>
  },
  {
    path: MAIN_PAGE_ROUTE,
    element: <MainPage/>
  },
  {
    path: SCHEDULE_PAGE_ROUTE,
    element: <SchedulePage/>
  },
  {
    path: CONTACT_PAGE_ROUTE,
    element: <ContactPage/>
  },
  {
    path: GALLERY_PAGE_ROUTE,
    element: <GalleryPage/>
  },
  {
    path: "*",
    element: <NoMatchPage/>
  }
]