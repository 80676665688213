import MainLayout from "../layouts/MainLayout";

function NoMatchPage() {
  return (
    <MainLayout>
      <section className="section-padding mt-5">
        <div className="container">
          <div className="flex-lg-row">
            <div className="col-lg-12">
              <div className="section-top text-center">
                <h1>Страница не найдена</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  );
}

export default NoMatchPage;