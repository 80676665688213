import React from 'react';
import {BrowserRouter} from "react-router-dom";
import AppRouter from "./components/AppRouter";
import "./assets/sass/style.scss"
import {createRoot, hydrateRoot} from "react-dom/client";

const rootElement  = document.getElementById('root');

const App = () => (
    <BrowserRouter>
      <AppRouter/>
    </BrowserRouter>
)



if (rootElement.hasChildNodes()) {
  hydrateRoot(rootElement , <App/>)
} else {
  createRoot(rootElement).render(<App/>);
}


