import MainLayout from "../layouts/MainLayout"
import {useEffect} from "react";
import {Helmet} from "react-helmet";

function ContactPage() {

  useEffect(() => {
    window.scrollTo({top:0,left:0});
  },[])
  return (
    <MainLayout>

      <Helmet>
        <title>Школа борьбы Superstar | Контакты</title>
        <meta name="description" content="Контакты" />
        <meta name="keywords" content="контакты, адрес, телефон" />
      </Helmet>

      <section className="contact-area section-padding">
        <div className="container">
          <div className="flex-lg-row">
            <div className="col-lg-12">
              <div className="section-top text-center">
                <h1>Контакты</h1>
              </div>
            </div>
          </div>


          <div className="row">
            <div className="col-lg-4 mb-5 mb-lg-0 mt-lg-3">
              <div className="d-flex">
                <div className="into-icon">
                  <i className="fa fa-map-marker"/>
                </div>
                <div className="info-text">
                  <h5>Филиал 1</h5>
                  <p>ул. Поляны, 5(этаж 3)</p>
                </div>
              </div>
              <div className="d-flex mt-2">
                <div className="into-icon">
                  <i className="fa fa-map-marker"/>
                </div>
                <div className="info-text">
                  <h5>Филиал 2</h5>
                  <p>Старобитцевская ул., 15, корп. 3</p>
                </div>
              </div>
              <div className="d-flex mt-2">
                <div className="into-icon">
                  <i className="fa fa-map-marker"/>
                </div>
                <div className="info-text">
                  <h5>Филиал 3</h5>
                  <p>Бартеневская ул., 19</p>
                </div>
              </div>

              <div className="d-flex mt-2">
                <div className="into-icon">
                  <i className="fa fa-phone"/>
                </div>
                <div className="info-text">
                  <h5><a className="span-style" href="tel:7 968 609-38-66">7 968 609-38-66</a></h5>
                  <p>ПН-ПТ 9:00-21:00</p>
                </div>
              </div>

              <div className="social-icons mt-3">
                <ul>
                  <li><a href="https://wa.me/79686093866" target="_blank"  rel="noreferrer"><i className="fa fa-whatsapp"/></a></li>
                  <li><a href="https://www.instagram.com/schoolsuperstar/?igshid=NTdlMDg3MTY%3D" target="_blank"  rel="noreferrer"><i className="fa fa-instagram"/></a></li>
                  <li><a href="https://vk.com/schoolsuperstar" target="_blank"  rel="noreferrer"><i className="fa fa-vk"/></a></li>
                </ul>
              </div>

            </div>



              <div className="col-lg-8 col-sm-12 p-0 animate__animated animate__backInRight" style={{position: 'relative', overflow: 'hidden'}}><a
                href="https://yandex.ru/maps/213/moscow/search/%D0%A8%D0%BA%D0%BE%D0%BB%D0%B0%20%D0%B1%D0%BE%D1%80%D1%8C%D0%B1%D1%8B%20Superstar/?utm_medium=mapframe&utm_source=maps"
                style={{color: '#eee', fontSize: '12px', position: 'absolute', top: '0px'}}>Школа борьбы Superstar в
                Москве</a><a href="https://yandex.ru/maps/213/moscow/?utm_medium=mapframe&utm_source=maps"
                             style={{color: '#eee', fontSize: '12px', position: 'absolute', top: '14px'}}>Москва</a>
                <iframe src="https://yandex.ru/map-widget/v1/-/CCUvf2H3lC" width={640} height={400}
                        allowFullScreen={true} style={{position: 'relative'}} title="map"/>
              </div>

          </div>





        </div>
      </section>




    </MainLayout>
  );
}

export default ContactPage;