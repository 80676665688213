function Footer() {
  return (
    <footer className="footer-area section-padding">
      <div className="footer-widget">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="single-widget-home mb-5 mb-lg-0">
                <h5 className="mb-4">О нас</h5>
                <p>Школа борьбы Superstar для детей и подростков в Бутово</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="single-widget-home mb-5 mb-lg-0">
                <h5 className="mb-4">Контакты</h5>
                <p>
                  <i className="fa fa-map-marker mr-2"/>ул. Поляны, 5, Москва (этаж 3) <br/>
                  <i className="fa fa-map-marker mr-2"/>Старобитцевская ул., 15, корп. 3, Москва <br/>
                  <i className="fa fa-map-marker mr-2"/>Бартеневская ул., 19, Москва<br/>
                  <i className="fa fa-phone mr-2"/><a href="tel:7 968 609-38-66" style={{color: "#777777"}}>7 968 609-38-66</a>
                </p>
                <a href="tel:7 968 609-38-66">7 968 609-38-66</a>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="single-widget-home">
                <h5 className="mb-4">Связаться</h5>
                <p className="mb-4">Вы можете обговорить все вопросы с тренером</p>
                <a href="https://wa.me/79686093866"  rel="noreferrer" target="_blank" className="template-btn">Написать нам</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-6">

            </div>
            <div className="col-lg-4 col-md-6">
              <div className="social-icons">
                <ul>
                  <li><a href="https://wa.me/79686093866" target="_blank"  rel="noreferrer"><i className="fa fa-whatsapp"/></a></li>
                  <li><a href="https://www.instagram.com/schoolsuperstar/?igshid=NTdlMDg3MTY%3D"  rel="noreferrer" target="_blank"><i className="fa fa-instagram"/></a></li>
                  <li><a href="https://vk.com/schoolsuperstar"  rel="noreferrer" target="_blank"><i className="fa fa-vk"/></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;